import React from 'react'
import styled from 'styled-components'
import { dimensions } from '../../../../utils/styles/styles'

const StyledContainerContent = styled.div`
  width: 100%;
  max-width: ${dimensions.siteWidth};
  margin: 40px auto;
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`

const ContainerContent = ({ children }) => {
  return (
    <StyledContainerContent>
      {children}
    </StyledContainerContent>
  )
}

ContainerContent.defaultProps = {
  
}

export default ContainerContent