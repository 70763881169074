import React from 'react'
import Layout from '../components/resuable/layout/layout'
import { graphql } from 'gatsby'
import ContainerContent from '../components/page/basicpage-components/container-content/container-content'
import Hero from '../components/resuable/hero/hero'

const PrivacyPolicy = ({ data }) => {
  const { title, content } = data.wordpressPage;
  return (
    <Layout>
      <Hero
        title={title}
      />
      <ContainerContent>
        <div 
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </ContainerContent>
    </Layout>
  )
}

export const query = graphql`
  query privacypolicypage {
    wordpressPage(slug: {eq: "privacy-policy"}) {
      title
      content
    }
  }
`

export default PrivacyPolicy
